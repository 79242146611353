// npm imports
import React from "react";

// intern imports.
import SikaLogoOnTableAndAbove from "../../assets/img/Sika_ClaimU_pos_rgb.svg";
import SikaLogoOnMobile from "../../assets/img/Sika_NoClaim_pos_rgb_mobile.png";
import PartnerLogo from "../../assets/img/logo_partner.jpg";
import Header from "../Header";

// local imports

export default function SikaHeader({ withBurger }) {
  return (
    <Header
      withBurger={withBurger}
      className="header header--with-partner header--carbon-footprint"
    >
      <h1>
        <img
          className="header__brand-logo display-on-mobile"
          src={SikaLogoOnMobile}
          alt="logo-sika"
        />
        <img
          className="header__brand-logo partner-logo display-on-mobile "
          src={PartnerLogo}
          alt="logo-partner"
        />

        <img
          className="header__brand-logo display-on-tablet-and-above"
          src={SikaLogoOnTableAndAbove}
          alt="logo-sika"
        />
        <img
          className="header__brand-logo partner-logo display-on-tablet-and-above "
          src={PartnerLogo}
          alt="logo-partner"
        />
      </h1>
    </Header>
  );
}
