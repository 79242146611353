// npm imports
import React, { Component } from "react";
import {
  //BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// intern imports.

// components
import MixAndFlow from "../MixAndFlowApp";
import Authentification from "../Authentification";
import LandingPage from "../AppLauncherPage";
import LegalNotice from "../LegalNotice";

// local imports

class App extends Component {
  state = {
    authorizedUser: localStorage.getItem("authorizedUser") === "true",
  };

  componentDidUpdate(prevProps, prevState) {
    let authorizedUser = localStorage.getItem("authorizedUser") ?? null;

    if (authorizedUser === "true") {
      authorizedUser = true;
    } else {
      authorizedUser = false;
    }

    if (!!authorizedUser && authorizedUser !== this.state.authorizedUser) {
      this.setState({ authorizedUser });
    }
  }

  authorizeCurrentUser = () => {
    this.setState({ authorizedUser: true }, () =>
      localStorage.setItem("authorizedUser", true)
    );
  };

  unauthorizeCurrentUser = () => {
    this.setState({ authorizedUser: false }, () =>
      localStorage.setItem("authorizedUser", false)
    );
  };

  render() {
    const { authorizedUser } = this.state;

    return (
      //<Router>
      <Routes>
        <Route
          exact
          path="/authentification"
          element={
            authorizedUser ? (
              <Navigate to="/" />
            ) : (
              <Authentification
                authorizeCurrentUser={this.authorizeCurrentUser}
              />
            )
          }
        />

        <Route
          exact
          path="/"
          element={
            authorizedUser ? (
              <Navigate to="/mix-and-flow" />
            ) : (
              <Authentification
                authorizeCurrentUser={this.authorizeCurrentUser}
              />
            )
          }
        />

        <Route
          exact
          path="/"
          element={
            !authorizedUser ? (
              <Navigate to="/authentification" />
            ) : (
              <LandingPage
                unauthorizeCurrentUser={this.unauthorizeCurrentUser}
              />
            )
          }
        />

        <Route
          exact
          path="/mix-and-flow"
          element={
            !authorizedUser ? (
              <Navigate to="/authentification" />
            ) : (
              <MixAndFlow
                unauthorizeCurrentUser={this.unauthorizeCurrentUser}
              />
            )
          }
        />

        <Route exact path="/mentions-legales" element={<LegalNotice />} />
      </Routes>
      //</Router>
    );
  }
}

export default App;
