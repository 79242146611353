export const homeText = {
  description: "",
  ctaButtons: [
    {
      text: "Equivalence<br>gamme Sika",
      screen: "sika-products",
    },
    {
      text: "Adjuvantation<br>sur-mesure",
      screen: "simulator",
    },
  ],
};
