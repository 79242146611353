export const authorizedUsers = [
  { login: "apsulis_partner", password: "sika_partner_2024" },
  { login: "aaron.debellu", password: "sika_appli_lafarge24!" },
  { login: "abdelaziz.soua", password: "sika_appli_lafarge24!" },
  { login: "abderahim.dib", password: "sika_appli_lafarge24!" },
  { login: "adelaide.fauchet", password: "sika_appli_lafarge24!" },
  { login: "adrien.gourdol", password: "sika_appli_lafarge24!" },
  { login: "adrien.michel", password: "sika_appli_lafarge24!" },
  { login: "alain.contini", password: "sika_appli_lafarge24!" },
  { login: "alexis.martin", password: "sika_appli_lafarge24!" },
  { login: "alrick.cathelineau", password: "sika_appli_lafarge24!" },
  { login: "aman.babio", password: "sika_appli_lafarge24!" },
  { login: "anas.bettioui", password: "sika_appli_lafarge24!" },
  { login: "anas.boussas", password: "sika_appli_lafarge24!" },
  { login: "annie.moreau", password: "sika_appli_lafarge24!" },
  { login: "antoine.jankovic", password: "sika_appli_lafarge24!" },
  { login: "antonio.domingues", password: "sika_appli_lafarge24!" },
  { login: "aziz.elatifi", password: "sika_appli_lafarge24!" },
  { login: "benjamin.vernier", password: "sika_appli_lafarge24!" },
  { login: "bertrand.dory", password: "sika_appli_lafarge24!" },
  { login: "brian.calandreau", password: "sika_appli_lafarge24!" },
  { login: "brice.balazard", password: "sika_appli_lafarge24!" },
  { login: "bruno.bachimont", password: "sika_appli_lafarge24!" },
  { login: "bruno.jouve", password: "sika_appli_lafarge24!" },
  { login: "cedric.maily", password: "sika_appli_lafarge24!" },
  { login: "cedric.ziolko", password: "sika_appli_lafarge24!" },
  { login: "celine.crouzoulon", password: "sika_appli_lafarge24!" },
  { login: "charles.power", password: "sika_appli_lafarge24!" },
  { login: "chloe.roche", password: "sika_appli_lafarge24!" },
  { login: "christine.siadoux", password: "sika_appli_lafarge24!" },
  { login: "christophe.lestrade", password: "sika_appli_lafarge24!" },
  { login: "claire.mouzon", password: "sika_appli_lafarge24!" },
  { login: "corinne.neyrand", password: "sika_appli_lafarge24!" },
  { login: "david.bellance", password: "sika_appli_lafarge24!" },
  { login: "david.cavagnac", password: "sika_appli_lafarge24!" },
  { login: "david.hatte", password: "sika_appli_lafarge24!" },
  { login: "david.ploivy", password: "sika_appli_lafarge24!" },
  { login: "david.vergnes", password: "sika_appli_lafarge24!" },
  { login: "dimitri.rubi", password: "sika_appli_lafarge24!" },
  { login: "dorian.luzineau", password: "sika_appli_lafarge24!" },
  { login: "eddy.romand", password: "sika_appli_lafarge24!" },
  { login: "elodie.haza", password: "sika_appli_lafarge24!" },
  { login: "elric.fillon", password: "sika_appli_lafarge24!" },
  { login: "emeline.delair", password: "sika_appli_lafarge24!" },
  { login: "emmanuelle.leboulch", password: "sika_appli_lafarge24!" },
  { login: "enora.genin", password: "sika_appli_lafarge24!" },
  { login: "fabien.salas", password: "sika_appli_lafarge24!" },
  { login: "fabien.therme", password: "sika_appli_lafarge24!" },
  { login: "fabrice.bonin", password: "sika_appli_lafarge24!" },
  { login: "fabrice.chevalier", password: "sika_appli_lafarge24!" },
  { login: "florian.barre", password: "sika_appli_lafarge24!" },
  { login: "fouad.el-jattari", password: "sika_appli_lafarge24!" },
  { login: "franck.pottier", password: "sika_appli_lafarge24!" },
  { login: "frederic.barthelemy", password: "sika_appli_lafarge24!" },
  { login: "gabi-gaiver.nze-doghman", password: "sika_appli_lafarge24!" },
  { login: "gaetan.esnault", password: "sika_appli_lafarge24!" },
  { login: "gerard.pinot", password: "sika_appli_lafarge24!" },
  { login: "gregory.picco", password: "sika_appli_lafarge24!" },
  { login: "guillaume.pouns", password: "sika_appli_lafarge24!" },
  { login: "guillaume.rocci", password: "sika_appli_lafarge24!" },
  { login: "gunther.grellier", password: "sika_appli_lafarge24!" },
  { login: "housni.ouled-mabtoul", password: "sika_appli_lafarge24!" },
  { login: "ibrahim.keita", password: "sika_appli_lafarge24!" },
  { login: "ines.laffetas", password: "sika_appli_lafarge24!" },
  { login: "isabelle.benoit", password: "sika_appli_lafarge24!" },
  { login: "jean-eudes.moreau", password: "sika_appli_lafarge24!" },
  { login: "jean-marc.salar", password: "sika_appli_lafarge24!" },
  { login: "jeanphilippe.sainturat", password: "sika_appli_lafarge24!" },
  { login: "jerome.albero", password: "sika_appli_lafarge24!" },
  { login: "jerome.bourbiot", password: "sika_appli_lafarge24!" },
  { login: "jhon.montes", password: "sika_appli_lafarge24!" },
  { login: "joachim.monge", password: "sika_appli_lafarge24!" },
  { login: "joel.pomies", password: "sika_appli_lafarge24!" },
  { login: "joffrey.cosse", password: "sika_appli_lafarge24!" },
  { login: "jonathan.haustein", password: "sika_appli_lafarge24!" },
  { login: "julien.coussin", password: "sika_appli_lafarge24!" },
  { login: "julien.jacquet", password: "sika_appli_lafarge24!" },
  { login: "julien.landemard", password: "sika_appli_lafarge24!" },
  { login: "julien.millat", password: "sika_appli_lafarge24!" },
  { login: "julien.pradier", password: "sika_appli_lafarge24!" },
  { login: "julien.throm", password: "sika_appli_lafarge24!" },
  { login: "justine.siepi", password: "sika_appli_lafarge24!" },
  { login: "karim.bougayou", password: "sika_appli_lafarge24!" },
  { login: "kelian.roger", password: "sika_appli_lafarge24!" },
  { login: "lamine.mendy", password: "sika_appli_lafarge24!" },
  { login: "laurenn.thebault", password: "sika_appli_lafarge24!" },
  { login: "laurent.benoteau", password: "sika_appli_lafarge24!" },
  { login: "laurent.passalacqua", password: "sika_appli_lafarge24!" },
  { login: "lili.baradel", password: "sika_appli_lafarge24!" },
  { login: "lucas.zerdi", password: "sika_appli_lafarge24!" },
  { login: "mandresy.rajaonina", password: "sika_appli_lafarge24!" },
  { login: "mathieu.duplessis", password: "sika_appli_lafarge24!" },
  { login: "mathieu.lehen", password: "sika_appli_lafarge24!" },
  { login: "mickael.hautreux", password: "sika_appli_lafarge24!" },
  { login: "mikael.juvin", password: "sika_appli_lafarge24!" },
  { login: "moktar.mazari", password: "sika_appli_lafarge24!" },
  { login: "murielle.dagorn", password: "sika_appli_lafarge24!" },
  { login: "nathalie.abeilhe", password: "sika_appli_lafarge24!" },
  { login: "nathalie.seillier", password: "sika_appli_lafarge24!" },
  { login: "nathan.thai-vuong", password: "sika_appli_lafarge24!" },
  { login: "nicolas.fontbonne", password: "sika_appli_lafarge24!" },
  { login: "nicolas.latchimy", password: "sika_appli_lafarge24!" },
  { login: "nicolas.magalhaes", password: "sika_appli_lafarge24!" },
  { login: "olivier.lardet", password: "sika_appli_lafarge24!" },
  { login: "olivier.loubineau", password: "sika_appli_lafarge24!" },
  { login: "papamoussagueye.ndao", password: "sika_appli_lafarge24!" },
  { login: "patrice.gomez", password: "sika_appli_lafarge24!" },
  { login: "patricia.lebaraillec", password: "sika_appli_lafarge24!" },
  { login: "patrick.aupetit", password: "sika_appli_lafarge24!" },
  { login: "philippe.cloarec", password: "sika_appli_lafarge24!" },
  { login: "philippe.fleury", password: "sika_appli_lafarge24!" },
  { login: "philippe.lesueur", password: "sika_appli_lafarge24!" },
  { login: "philippe.persehaie", password: "sika_appli_lafarge24!" },
  { login: "philippe.reillat", password: "sika_appli_lafarge24!" },
  { login: "pierre.escobar", password: "sika_appli_lafarge24!" },
  { login: "pierric.chupin", password: "sika_appli_lafarge24!" },
  { login: "quentin.danilo", password: "sika_appli_lafarge24!" },
  { login: "quentin.deleglise", password: "sika_appli_lafarge24!" },
  { login: "quentin.girard", password: "sika_appli_lafarge24!" },
  { login: "quentin.ritte", password: "sika_appli_lafarge24!" },
  { login: "rabah.mahrouchi", password: "sika_appli_lafarge24!" },
  { login: "rachid.ziane", password: "sika_appli_lafarge24!" },
  { login: "rayan.gharbi", password: "sika_appli_lafarge24!" },
  { login: "regis.gallon", password: "sika_appli_lafarge24!" },
  { login: "romain.dehais", password: "sika_appli_lafarge24!" },
  { login: "romain.mimouni", password: "sika_appli_lafarge24!" },
  { login: "romain.tergella", password: "sika_appli_lafarge24!" },
  { login: "sabrina.pinotti", password: "sika_appli_lafarge24!" },
  { login: "salome.richard", password: "sika_appli_lafarge24!" },
  { login: "sebastien.charuel", password: "sika_appli_lafarge24!" },
  { login: "sebastien.gougeon", password: "sika_appli_lafarge24!" },
  { login: "sebastien.rols", password: "sika_appli_lafarge24!" },
  { login: "sebastien.simeon", password: "sika_appli_lafarge24!" },
  { login: "sophie.gatt", password: "sika_appli_lafarge24!" },
  { login: "soulayman.abdulkader", password: "sika_appli_lafarge24!" },
  { login: "stephane.even", password: "sika_appli_lafarge24!" },
  { login: "steve.cadot", password: "sika_appli_lafarge24!" },
  { login: "thierry.coutellier", password: "sika_appli_lafarge24!" },
  { login: "thomas.puntillo", password: "sika_appli_lafarge24!" },
  { login: "valentin.menjoulet", password: "sika_appli_lafarge24!" },
  { login: "valerie.batret", password: "sika_appli_lafarge24!" },
  { login: "veronique.despres", password: "sika_appli_lafarge24!" },
  { login: "vincent.benoteau", password: "sika_appli_lafarge24!" },
  { login: "vincent.rochette", password: "sika_appli_lafarge24!" },
  { login: "yanis.rodriguez", password: "sika_appli_lafarge24!" },
  { login: "yaya.keita", password: "sika_appli_lafarge24!" },
  { login: "yohan.berthemet", password: "sika_appli_lafarge24!" },
];
