export const launcherText = {
  title: "Applications pôle béton",
  description: "",
  ctaButtons: [
    {
      text: "Mix and Flow",
      link: "/mix-and-flow",
    },
  ],
};
